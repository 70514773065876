import { Reducer } from 'redux';
import { SET_IS_LOADING } from '../actions/actionTypes';

export const loadingReducer: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case SET_IS_LOADING:
            let isLoading: any = action.payload;
            return isLoading;
        default:
            return state;
    }
};