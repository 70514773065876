import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import { userReducer } from "./reducers/userReducer";
import { AuthenticatedUser } from "../model/AuthenticatedUser";
import { CustomError } from "../model/Error";
import { Page } from "powerbi-client";
import { pageReducer } from "./reducers/pageReducer";
import { loadingReducer } from "./reducers/loadingReducer";
import { printMenuItemsReducer } from "./reducers/printMenuItemsReducer";
import { reportReducer } from "./reducers/reportReducer";
import { errorReducer } from "./reducers/errorReducer";
import { EmbedResult } from "../model/EmbedResult";
import { OptionsType, OptionTypeBase } from "react-select";
import { firmReducer } from "./reducers/firmReducer";
import { isOpenReducer } from "./reducers/isOpenReducer";

export interface State {
    currentlyAuthenticatedUser: AuthenticatedUser,
    pages: Array<Page>,
    printMenuItems: Array<any>,
    report: EmbedResult[],
    currentPage: string,
    isOpen: boolean,
    firms: OptionsType<OptionTypeBase>,
    error: CustomError,
    isLoading: any
}

export default (history:any) => combineReducers({
    currentlyAuthenticatedUser: userReducer,
    pages: pageReducer,
    printMenuItems: printMenuItemsReducer,
    report: reportReducer,
    firms: firmReducer,
    error: errorReducer,
    isOpen: isOpenReducer,
    isLoading: loadingReducer,
    router: connectRouter(history)
});