import * as Msal from 'msal';
import { Environment } from '../model/Environment';

let environment = Environment.DEV;
let subdomain = 'dev';
let account;
let signUpSignInAuth;
let forgotPasswordAuth;
let clientId;
let b2cScopes;
let telemetryInstrumentationKey;
const hostname = window && window.location && window.location.hostname;

// Config for localhost
if (hostname === 'localhost' || hostname === 'alasdia-uscu-dev-diawebapps.azurewebsites.net' || hostname === 'datainsights-dev.alas.com') {
    // DEV config
    environment = Environment.DEV;
    signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/ALASB2CDev.onmicrosoft.com/B2C_1A_RD_USERSIGNIN_DATAINSIGHTS';
    //signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin_dataview/';
    forgotPasswordAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/';
    clientId = '75ad60cf-71ad-41d0-8528-1ab8540846c6';
    b2cScopes = ['https://ALASB2CDev.onmicrosoft.com/a5e4e417-17a9-4242-8ce9-78b118bb156c/DIAWebApps.Read'];
    telemetryInstrumentationKey = '710a4b41-ab70-4a1f-829c-a3fbbff40b5d';
    subdomain = 'dev';
    account = `alasdiauscudev`;
} else if (hostname === 'alasdia-uscu-test-diawebapps.azurewebsites.net' || hostname === 'datainsights-test.alas.com') {
    // Test config 
    environment = Environment.Test;
    signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/ALASB2CDev.onmicrosoft.com/B2C_1A_RD_USERSIGNIN_DATAINSIGHTS';
    // signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin_test_dataview/';
    forgotPasswordAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/';
    clientId = '75ad60cf-71ad-41d0-8528-1ab8540846c6';
    b2cScopes = ['https://ALASB2CDev.onmicrosoft.com/a5e4e417-17a9-4242-8ce9-78b118bb156c/DIAWebApps.Read'];
    telemetryInstrumentationKey = '8398e44c-6475-4afb-b5b5-8cc563ca1e5b';
    subdomain = 'staging';
    account = `alasdiauscutest`;
} else if (hostname === 'alasdia-uscu-demo-diawebapps.azurewebsites.net' || hostname === 'datainsights-demo.alas.com') {
    // Demo config
    environment = Environment.DEMO;
    signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/ALASB2CDev.onmicrosoft.com/B2C_1A_RD_USERSIGNIN_DATAINSIGHTS';
    // signUpSignInAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin_test_dataview/';
    forgotPasswordAuth = 'https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/';
    clientId = '75ad60cf-71ad-41d0-8528-1ab8540846c6';
    b2cScopes = ['https://ALASB2CDev.onmicrosoft.com/a5e4e417-17a9-4242-8ce9-78b118bb156c/DIAWebApps.Read'];
    telemetryInstrumentationKey = 'ca4ff1ba-10e8-4054-8dd0-88d51d9169b6';
    subdomain = 'demo';
    account = `alasdiauscudemo`;
} else if (hostname === 'alasdia-uscu-prd-diawebapps.azurewebsites.net' || hostname === 'datainsights.alas.com') {
    // PROD config
    environment = Environment.PROD;
    signUpSignInAuth = 'https://ALASB2CProd.b2clogin.com/ALASB2CProd.onmicrosoft.com/B2C_1A_RD_USERSIGNIN_DATAINSIGHTS';
    // signUpSignInAuth = 'https://ALASB2CProd.b2clogin.com/tfp/ALASB2CProd.onmicrosoft.com/B2C_1A_signup_signin_dataview/';
    forgotPasswordAuth = 'https://ALASB2CProd.b2clogin.com/tfp/ALASB2CProd.onmicrosoft.com/B2C_1A_PasswordReset/';
    clientId = '62daba02-1f90-4634-bc05-3ee72a938785';
    b2cScopes = ['https://alasb2cprod.onmicrosoft.com/f655bdda-f84b-412e-abb9-d1b3dcc5f97a/DIAWebApps.Read'];
    telemetryInstrumentationKey = '6564cbd3-161f-4cf6-b6b3-d4ec5f0d56c0';
    subdomain = 'www';
    account = `alasdiauscuprd`;
} else {
    throw 'Environment not implemented: ' + hostname;
}

export const authorities = {
    signUpSignIn: {
        authority: signUpSignInAuth
    },
    forgotPassword: {
        authority: forgotPasswordAuth
    }
}

export const msalConfig = {
    auth: {
        validateAuthority: false,
        cacheLocation: 'sessionStorage',
        clientId: clientId,
        authority: authorities.signUpSignIn.authority,
        b2cScopes: b2cScopes,
        postLogoutRedirectUri: `https://${hostname}`,
        // redirectUri: 'http://localhost:3000'
    }
};

// create UserAgentApplication instance
export const myMSALObj = new Msal.UserAgentApplication(msalConfig);

// request to signin - returns an idToken
export const loginRequest = {
    scopes: ["openid", "profile"],
};

// request to acquire a token for resource access
export const tokenRequest = {
    scopes: msalConfig.auth.b2cScopes
};
//Application insight instrumentation key
export const Telemetry_Instrumentation_Key = telemetryInstrumentationKey;

// Enter your storage appService name and shared key
export const azureAccount = account;
export const Subdomain = subdomain;
export const appEnvironment = environment;