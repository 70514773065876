
import { Reducer } from 'redux';
import { TOGGLE } from '../actions/actionTypes';

export const isOpenReducer: Reducer<Boolean> = (state = false, action) => {
    switch (action.type) {
        case TOGGLE:
            return action.payload;
        default:
            return state;
    }
};