import { Reducer } from 'redux';
import { SET_ALL_FIRMS } from '../actions/actionTypes';
import { OptionsType, OptionTypeBase } from 'react-select';

export const firmReducer: Reducer<OptionsType<OptionTypeBase>> = (state = [], action) => {
    switch (action.type) {
        case SET_ALL_FIRMS:
            let firms: Array<OptionsType<OptionTypeBase>> = action.payload;
            return firms;
        default:
            return state;
    }
};