
import { Reducer } from 'redux';
import { SET_ALL_PAGES } from '../actions/actionTypes';
import { Page } from 'powerbi-client';

export const pageReducer: Reducer<Array<Page>> = (state = [], action) => {
    switch (action.type) {
        case SET_ALL_PAGES:
            let pages: Array<Page> = action.payload;
            return pages;
        default:
            return state;
    }
};