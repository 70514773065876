import { EmbedToken } from "./EmbedToken";

export class EmbedResult {
    constructor(
        public id: string = ``,
        public embedUrl: string = ``,
        public embedToken: EmbedToken = new EmbedToken(),
        public minutesToExpiration: number = -1,
        public isEffectiveIdentityRolesRequired: boolean = false,
        public isEffectiveIdentityRequired: boolean = false,
        public enableRLS: boolean = false,
        public username: string = ``,
        public roles: string = ``,
        public reportGroup: string = ``
    ) {

    }
}

