import { azureAccount } from '../store/authConfig';

async function apiGet(url: string, accessToken: string) {
    const dataResponse = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
            }
        })
    if (dataResponse.status === 401){
        return dataResponse;
    }
    if (dataResponse.status > 500 && dataResponse.status < 599) {
        return Promise.reject(dataResponse);
    }
    return dataResponse.json()
        .catch((error: any) => {
            console.log(error)
        });
}

function fetchTableData(url: string): Promise<any>{

    return fetch(`${url}`, {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
      if (response.ok) {
        return response.text().then(sitesCsv => {
            return JSON.parse(sitesCsv)
        });
     }  else if (response.status === 404) {
        return null;
      } else if (response.status > 500 && response.status < 599) {
        return Promise.reject(response);
      } else {
        var error = new Error("Fetching table data");
        (error as any).response = response;
        throw error;
      }
    });
  }

export async function apiReports(accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/Report?isMobile=${isMobile}`, accessToken);
}

export async function apiReportsWithFirmOverride(id: string, accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/Report?firmId=${id}&isMobile=${isMobile}`, accessToken);
}

export async function apiPages(accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/Pages?isMobile=${isMobile}`, accessToken);
}

export async function apiPagesWithFirmOverride(id: string, accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/Pages?firmId=${id}&isMobile=${isMobile}`, accessToken);
}

export async function apiPrintMenuItems(accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/PrintMenuItems?isMobile=${isMobile}`, accessToken);
}

export async function apiFirmDropDown(accessToken: string) {
    var isMobile = window.innerWidth <= 576;
    return await apiGet(`PowerBI/FirmDropDown?isMobile=${isMobile}`, accessToken);
}
export async function getHelpCategoriesAssetsTable(helpCategoryAssets: string, categoryAssetAccess: string) { 
    const helpCategoriesAssetsTableApiUrl = `https://${azureAccount}.table.core.windows.net/${helpCategoryAssets}`;
    const url = `${helpCategoriesAssetsTableApiUrl}${categoryAssetAccess}`;
    return await fetchTableData(`${url}`); 
}
export async function getHelpAssetsTable(helpAssets: string, assetAccess: string) { 
    const helpAssetsTableApiUrl = `https://${azureAccount}.table.core.windows.net/${helpAssets}`;
    const url = `${helpAssetsTableApiUrl}${assetAccess}`;
    return await fetchTableData(`${url}`); 
}
 