
import { Reducer } from 'redux';
import { SET_ALL_PRINT_MENU_ITEMS } from '../actions/actionTypes';

export const printMenuItemsReducer: Reducer<Array<any>> = (state = [], action) => {
    switch (action.type) {
        case SET_ALL_PRINT_MENU_ITEMS:
            let pages: Array<any> = action.payload;
            return pages;
        default:
            return state;
    }
};