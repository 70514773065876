import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { myMSALObj } from '../../store/authConfig';

let autoLogout: NodeJS.Timeout

interface IWarningProps {
    show: boolean;
    message?: string;
    minutes: number;
    close: any;
}

interface IWarningState {
    open: boolean;
    time: any;
    seconds: number;
}

class Warning extends React.Component<IWarningProps, IWarningState> {

    private timer;
    private startDate;

    constructor(props: IWarningProps) {
        super(props);
        this.state = { open: this.props.show, time: this.secondsToTime(this.props.minutes * 60), seconds: this.props.minutes * 60 };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.startDate = new Date();
    }

    secondsToTime(secs: number) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        autoLogout = setTimeout(this.logout, this.props.minutes * 60000);
        this.startTimer();
    }

    startTimer() {
        this.timer = window.setInterval(this.countDown, 1000);
    }

    countDown() {
        // update seconds, set state so a re-render happens.
        let seconds = this.calcSecondsLeft();
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }

    calcSecondsLeft() {
        // calculate seconds passed
        let currentDate = new Date();
        let millisecondsPassed = currentDate.getTime() - this.startDate.getTime();
        let secondsPassed = Math.floor(millisecondsPassed / 1000);

        // subtract seconds passed from total seconds
        return Math.max(0, (this.props.minutes * 60) - secondsPassed);
    }

    close() {
        this.props.close();
        clearTimeout(autoLogout);
    }

    logout() {
        myMSALObj.logout();
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    open={this.props.show}
                    onClose={() => { this.close() }}
                >
                    <DialogTitle color="white" bgcolor="#02568A" style={{ paddingTop: '10px', paddingBottom: '10px'}}>Session timeout warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ width: '310px' }}>
                            <br />Your session will expire in {this.state.time.m} min {this.state.time.s} sec.
                            <br />Select "Continue" to extend session.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ borderTop: '1px solid #fafafa', paddingTop: '10px', paddingBottom: '10px'}}>
                        <Button onClick={() => { myMSALObj.logout() }}>Logout</Button>
                        <Button onClick={() => { this.close() }}>Continue</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}


export default Warning;
