import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, ListGroupItemHeading, Nav, NavItem, NavLink } from 'reactstrap';
import { HelpCategory, HelpTopic } from '../../model/HelpTopic';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IHelpTopicProps {
    helpCategoryTopic: HelpCategory;
    helpTopic: HelpTopic;
    allHelpCategories: Array<HelpCategory>;
}

interface IHelpTopicState {
    hasVideo: boolean;
    isDrawerOpen: boolean;
}

class HelpTopicDetails extends React.Component<IHelpTopicProps, IHelpTopicState>{
    constructor(props: any) {
        super(props);
        this.state = {
            hasVideo: true,
            isDrawerOpen: true
        };
    }
    public render() {
        // Create consts variables with fallbacks
        const helpCategoryTitle = this.props.helpCategoryTopic ? this.props.helpCategoryTopic.catergoryName : '';
        const helpTitle = this.props.helpTopic.title ? this.props.helpTopic.title : '';
        const helpHtmlText = this.props.helpTopic.htmlText ? this.props.helpTopic.htmlText : '';
        const helpVideoUrl = this.props.helpTopic.videoUrl ? this.props.helpTopic.videoUrl : 'missing';
        const helpVideoHeight = this.props.helpTopic.videoHeight;
        const helpVideoWidth = this.props.helpTopic.videoWidth;
        const helpPdfUrl = this.props.helpTopic.pdfUrl ? this.props.helpTopic.pdfUrl : 'missing';
        const helpHtmlUrl = this.props.helpTopic.url ? this.props.helpTopic.url : 'missing';
        const otherHelpTopics: HelpCategory[] = this.props.allHelpCategories.filter((helpCategory: HelpCategory) => helpCategory.catergoryName !== helpCategoryTitle)
        const categoryChildHelpTitles: any[] = this.props.helpCategoryTopic.childHelpTopics.map((topic: HelpTopic) => {
            return { key: topic.key, title: topic.title };
        });

        return (
            <Row className='mainRow' >
                <Col md={12} lg={12} >
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={`?category=${helpCategoryTitle}`}>
                                {helpCategoryTitle}
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{helpTitle}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
                <Col md={9} lg={9} >
                    <h2>{helpTitle}</h2>
                    {helpVideoUrl !== 'missing' && (
                        <div style={{ height: 'auto', width: 'auto', textAlign: "center" }}>
                            <iframe src={helpVideoUrl} name="azuremediaplayer" scrolling="no" height={helpVideoHeight} width={helpVideoWidth} ></iframe>
                        </div>
                    )
                    }
                    {helpHtmlUrl !== 'missing' && helpHtmlText !== '' && (
                        <div dangerouslySetInnerHTML={{ __html: helpHtmlText }}></div>
                    )
                    }
                    {helpPdfUrl !== 'missing' && (
                        <a href={helpPdfUrl} title={helpTitle}>PDF Download</a>
                    )
                    }
                </Col>
                <Col md={3} lg={3} style={{ marginTop: '15px' }}>
                    <ListGroup>
                        <ListGroupItemHeading disabled>{helpTitle}</ListGroupItemHeading>
                        {categoryChildHelpTitles.map((topic: any) => {
                            return (
                                <ListGroupItem
                                    color='#000'
                                    to={`?category=${helpCategoryTitle}&topic=${topic.key}`}
                                    key={topic.key}
                                    active={topic.title === helpTitle}
                                    disabled={topic.title === helpTitle}
                                    tag={Link}
                                    href="#"
                                    action
                                >
                                    {topic.title}
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>

                    {otherHelpTopics ? (
                        <Nav vertical style={{ marginBottom: '20px' }}>
                            <h5 style={{ margin: '20px 0 10px' }}>Other Help Topics</h5>
                            {otherHelpTopics.map((otherHelpCategory: HelpCategory) => {
                                return (
                                    <NavItem key={otherHelpCategory.catergoryName}>
                                        <Link to={`?category=${otherHelpCategory.catergoryName}`}>
                                            {otherHelpCategory.catergoryName}
                                        </Link>
                                    </NavItem>
                                )
                            }
                            )}
                        </Nav>
                    ) : <></>}

                </Col>
            </Row>
        );
    }

};

export default connect()(HelpTopicDetails);

