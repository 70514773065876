//#region User Actions
export const CURRENT_USER_SET = 'CURRENT_USER_SET';
//#endregion

//#region Page Actions
export const SET_ALL_PAGES = 'SET_ALL_PAGES';
//#endregion

//#region Print Menu Items Actions
export const SET_ALL_PRINT_MENU_ITEMS = 'SET_ALL_PRINT_MENU_ITEMS';
//#endregion

//#region Firm Actions
export const SET_ALL_FIRMS = 'SET_ALL_FIRMS';
//#endregion

//#region Report Actions
export const SET_REPORT = 'SET_REPORT';
//#endregion

//#region isOpen Actions
export const TOGGLE = 'TOGGLE';
//#endregion

//#region Error Actions
export const SET_ALL_ERRORS = 'SET_ALL_ERRORS';
//#endregion

//#region Loading Actions
export const SET_IS_LOADING = 'SET_IS_LOADING';
//#endregion