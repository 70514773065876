
import { Reducer } from 'redux';
import { SET_ALL_ERRORS } from '../actions/actionTypes';
import { CustomError } from '../../model/Error';

export const errorReducer: Reducer<CustomError> = (state = new CustomError(), action) => {
    switch (action.type) {
        case SET_ALL_ERRORS:           
            let error = action.payload;
            return error;      
        default:
            return state;
    }
};