import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavLink, NavItem, Nav } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { Circle } from 'rc-progress';
import { getAppInsights, getReactPlugin } from '../common/TelemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface IExportProps {
}

interface IExportState {
    isLoading: boolean;
    competed?: number;
    errorMessage: string;
}

const svgStyle = {
    width: '200px',
    margin: '30px 0 0'
};

const centerText = {
    textAlign: 'center' as 'center',
    padding: '100px 0'
};


class Export extends React.Component<IExportProps, IExportState>{

    constructor(props: IExportProps) {
        super(props);
        this.state = {
            isLoading: true,
            competed: 0,
            errorMessage: ""
        };

        getAppInsights().trackPageView();
    }
    public componentDidMount() {
        this.setState({ isLoading: true });
        var isMobile = window.innerWidth <= 576;
        fetch(`PowerBI/Export?isMobile=${isMobile}`)
            .then(response => {
                if (response.ok) {
                    this.setState({ isLoading: true });
                    setInterval(() => {
                        if (this.state.isLoading == true) {
                            fetch(`PowerBI/Poll?isMobile=${isMobile}`)
                                .then(response => response.json() as any)
                                .then(data => {
                                    this.setState({ competed: data, isLoading: true });
                                    if (this.state.competed == 100 && this.state.isLoading == true) {
                                        //Download report
                                        window.location.href = (`/PowerBI/Download?isMobile=${isMobile}`);
                                        this.setState({ isLoading: false });
                                    }
                                });
                        }
                    }, 10000)
                }
                else {
                    this.setState({ errorMessage: "Something went wrong while exporting report." })
                    setTimeout(() => { window.location.href = ('/'); }, 5000);
                }
            })            
    }

    public render() {
        return (
            <>
                <Row className='mainRow' style={{ height: '95%' }}>
                    <Col style={centerText} sm={12}>
                        <h4> Report export request has been submitted, it will take about 2 minutes to complete.</h4>
                        {this.state.errorMessage ? <p>{this.state.errorMessage}</p>:<></>}
                        {this.state.competed != undefined && this.state.competed < 100 ?
                            <>
                                <div>{this.state.competed} % completed.</div>
                                <Circle style={svgStyle} percent={this.state.competed} strokeWidth={4} strokeColor="#00aeef" />
                                </>
                            : <> <Nav className="navbar-nav flex-grow">
                                <NavItem>
                                <NavLink className="navbarBrandStyle active" tag={Link} to="/">
                                    <p>Go back to Report</p>
                                    </NavLink>
                                </NavItem>
                                </Nav>
                            </>
                            }
                    </Col>

                </Row>

            </>
        );
    }
};

export default connect()(Export);
