import React from 'react';
import { Collapse, Container, Navbar } from 'reactstrap';
import { myMSALObj, tokenRequest } from '../store/authConfig';
import { setCurrentUserAction } from '../store/actions/userActions';
import { setAllPagesAction } from '../store/actions/pageActions';
import { AuthResponse } from 'msal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State } from '../store/rootReducer';
import { AuthenticatedUser } from '../model/AuthenticatedUser';
import { apiReportsWithFirmOverride, apiPagesWithFirmOverride } from '../api/Api';
import { EmbedResult } from '../model/EmbedResult';
import PrintDropDown from './buttons/PrintDropDown';
import { setReportAction } from '../store/actions/reportActions';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import Drawer from '@mui/material/Drawer';
import Error from './layout/Error';

// Props
interface INavProps {
    isMobile?: boolean;
}

interface INavStoreProps {
    currentlyAuthenticatedUser: AuthenticatedUser;
    firms: OptionsType<OptionTypeBase>;
    printMenuItems: Array<any>;
}

// Component State
interface INavState {
    isOpen: boolean;
    isLoggedIn: boolean;
    disableDropdown: boolean;
    majorError: boolean;
    errorMsg?: string;
}

// Dispatch to redux
interface INavDispatchProps {
    setCurrentUser: (authUser: AuthenticatedUser) => void;
    setReport: (report: EmbedResult[]) => void;
    setPages: (pages: any) => void;
}

const mainContainer = {
    maxWidth: 'none', padding: '0'
};

const headerContainer = {
    height: '4vh',
    marginRight: '16px'
};

const headerNav = {
    width: '100%'
};

const firmDropdown = {
    marginRight: '15px'
};

const reportsBtn = {
    width: '140px',
    fontSize: '13px',
    padding: '5px 10px',
    borderRadius: 0,
    textTransform: 'uppercase' as 'uppercase'
};

class NavMenu extends React.Component<INavDispatchProps & INavStoreProps & INavProps, INavState>{

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: true,
            isLoggedIn: false,
            disableDropdown: this.props.currentlyAuthenticatedUser.isALAS(),
            majorError: false,
            errorMsg: undefined
        };
    }

    render() {
        return (
            <Container fluid id="top-nav-container">
                <Error show={this.state.majorError} errorMsg={undefined} />
                <Drawer
                    keepMounted={true}
                    variant={'permanent'}
                    open={true}
                    anchor={'top'}
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent"
                        }
                    }}
                >
                    <header style={headerContainer} id="top-drawer-outer">
                        <Navbar style={headerNav} className="navbar-expand-sm navbar-toggleable-sm">
                            <Container style={mainContainer}>
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={true} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        {this.props.currentlyAuthenticatedUser.isALAS() &&
                                            <div style={{ width: '250px' }}>
                                                <Select
                                                    options={this.props.firms}
                                                    onChange={option => this.changeFirmDD(option)}
                                                    placeholder="Search/Select Firm"
                                                    style={firmDropdown}
                                                />
                                            </div>}
                                        {<PrintDropDown disableDropdown={false} printMenuItems={this.props.printMenuItems} />}
                                    </ul>
                                </Collapse>
                            </Container>
                        </Navbar>
                    </header>
                </Drawer>
            </Container>
        );
    }

    private changeFirmDD = (option: any) => {
        this.setState({ disableDropdown: false });
        var authenticatedUser: AuthenticatedUser = this.props.currentlyAuthenticatedUser;
        authenticatedUser.impersonatedFirmId = option!.value;
        this.props.setCurrentUser(authenticatedUser);

        myMSALObj.acquireTokenSilent(tokenRequest)
            .then((tokenResponse: AuthResponse) => {
                apiReportsWithFirmOverride(option!.value, tokenResponse.accessToken)
                    .then((apiResp: any) => {
                        this.props.setReport(apiResp);
                    });

                apiPagesWithFirmOverride(option!.value, tokenResponse.accessToken)
                    .then((apiResp: any) => {
                        this.props.setPages(apiResp);
                    })
            })
            .catch((error: any) => {
                this.setState({ majorError: true, errorMsg: undefined })
                // fallback to interaction when silent call fails
                return myMSALObj.acquireTokenRedirect(tokenRequest);
            });
    }
}

function mapStateToProps(state: State) {
    return {
        currentlyAuthenticatedUser: state.currentlyAuthenticatedUser,
        firms: state.firms,
        printMenuItems: state.printMenuItems
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setCurrentUser: setCurrentUserAction,
            setReport: setReportAction,
            setPages: setAllPagesAction,
        },
        dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);