import * as React from 'react';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import { ParsedQuery } from 'query-string';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row, Col, Card, Button, CardImg, CardTitle, CardText, CardDeck, CardBody, NavLink } from 'reactstrap';
import { getAppInsights } from '../../common/TelemetryService';
import { HelpCategory, HelpTopic, HelpTopicAsset, HelpSection, HelpCategoryAsset } from '../../model/HelpTopic';
import { sortArrayElementsByOrder } from '../../common/GeneralHelpers';
import { getHelpAssetsTable, getHelpCategoriesAssetsTable } from '../../api/Api';
import HelpTopicDetails from './HelpTopicDetails';
import HelpCategoryTopic from './HelpCategoryTopic';
import LeftNav from '../LeftNav';
import Footer from '../layout/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Error from '../layout/Error';

interface IHelpProps {
    match: any;
    currentReportGroup: string;
}

interface IHelpState {
    isLoading: boolean;
    allHelpCategories: Array<HelpCategory>;
    activeHelpCategoryTopic: HelpCategory;
    activeHelpTopic: HelpTopic;
    helpSection: HelpSection;
    urlRouteKey: string;
    isDrawerOpen: boolean;
    majorError: boolean;
    errorMsg?: string;
    isTablet: boolean;
    isMobile: boolean;
    helpAssets: string;
    helpCategoryAssets: string;
    categoryAssetAccess: string;
    assetAccess: string;
    isLoadingAssets: boolean;
}


class Help extends React.Component<RouteComponentProps<any> & IHelpProps, IHelpState> {
    hasMounted: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            allHelpCategories: [],
            activeHelpCategoryTopic: { catergoryName: '', childHelpTopics: [], order: 99999 },
            activeHelpTopic: { key: '', title: '', name: '', helpCategory: '', description: '', htmlText: '', order: -1, url: '', videoUrl: '', videoHeight: '', videoWidth: '', pdfUrl: '', imageUrl: '' },
            helpSection: HelpSection.HelpLoading,
            urlRouteKey: '',
            isDrawerOpen: true,
            majorError: false,
            errorMsg: undefined,
            isMobile: false,
            isTablet: false,
            helpAssets: '',
            helpCategoryAssets: '',
            categoryAssetAccess: '',
            assetAccess: '',
            isLoadingAssets: false
        };
        getAppInsights().trackPageView();
    }

    public componentDidMount() {
        this.setState({ isLoadingAssets: true, helpSection: HelpSection.HelpLoading });
        fetch(`HelpStorage/Help?${new URLSearchParams({ reportGroup: this.props.currentReportGroup })}`)
            .then(response => response.json() as any)
            .then(data => {
                this.setState({
                    helpAssets: data.helpAssets,
                    helpCategoryAssets: data.helpCategoryAssets,
                    categoryAssetAccess: data.helpCategoriesAccess,
                    assetAccess: data.helpAssetsAccess,
                    isLoadingAssets: false
                });
                return this.generateHelpCategories(data.fileList);
            })
            .catch(err => {
                this.setState({ majorError: true, errorMsg: undefined });
            });
    }

    public componentDidUpdate(prevProps: IHelpProps, prevState: IHelpState) {
        let urlSearchKey: string = this.props.location.search;
        
        // Load Help/ (Help Landing)
        if (urlSearchKey === '' && prevState.urlRouteKey !== this.state.urlRouteKey) {
            this.updateContentPerRoute();
        }
        // Load Help/ (Help Landing) coming from a topic or category
        if (urlSearchKey === '' && prevState.urlRouteKey.includes('category')) {
            this.updateContentPerRoute();
        }
        // Load either a Category or Topic
        if (urlSearchKey !== '' && urlSearchKey !== this.state.urlRouteKey) {
            let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
            // Load one help category only
            if (queryStringResult.category && !queryStringResult.topic && urlSearchKey !== this.state.urlRouteKey) {
                this.updateContentPerRoute(queryStringResult.category);
                // Load one help topic
            } else if (queryStringResult.category && queryStringResult.topic && urlSearchKey !== this.state.urlRouteKey) {
                this.updateContentPerRoute(queryStringResult.category, queryStringResult.topic);
            }
        }
        if (this.props.currentReportGroup !== prevProps.currentReportGroup) {
            // Fetch data from the HelpStorageController, then pass data (this.generateHelpCategories(data))
            // and create this.state.allHelpCategories
            
            if (!this.state.isLoadingAssets) {
                this.setState({ isLoadingAssets: true, helpSection: HelpSection.HelpLoading });
                fetch(`HelpStorage/Help?${new URLSearchParams({ reportGroup: this.props.currentReportGroup })}`)
                    .then(response => response.json() as any)
                    .then(data => {
                        this.setState({
                            helpAssets: data.helpAssets,
                            helpCategoryAssets: data.helpCategoryAssets,
                            categoryAssetAccess: data.helpCategoriesAccess,
                            assetAccess: data.helpAssetsAccess,
                            isLoadingAssets: false
                        });
                        return this.generateHelpCategories(data.fileList);
                    })
                    .catch(err => {
                        this.setState({ majorError: true, errorMsg: undefined });
                    });
            }
        }
    }

    componentWillUnmount() {
        this.hasMounted = false;
    }

    public render() {

        return (
            <>
                <Error show={this.state.majorError} errorMsg={this.state.errorMsg} />
                <Row className='mainRow'>
                    <LeftNav currentReportGroup={this.props.currentReportGroup} currentPage="help" toggleDrawer={this.toggleDrawer} setIsMobile={this.setIsMobile} setIsTablet={this.setIsTablet} />
                    <Col style={{ padding: this.state.isMobile ? '0px 0px 0px 20px' : '0px 0px 0px 1%', marginLeft: this.state.isMobile ? '0px' : this.state.isTablet ? '30px' : this.state.isDrawerOpen ? '220px' : '20px' }}>
                        <h1 style={{ margin: this.state.isMobile ? '42px 0 30px 20px' : '42px 0 30px' }}>Help Pages</h1>
                        <NavLink className="support-link" href="mailto:membersupport@alas.com">
                            Contact ALAS
                        </NavLink>
                        <div id="wrapper">
                            <div id="content">
                                <div className="clearfix help-content" style={{ marginLeft: this.state.isMobile ? '20px' : '0px', height: '100%' }}>
                                    {(this.state.helpSection === HelpSection.HelpLoading || this.state.isLoadingAssets) && (<div style={{ margin: '50vh 50%', color: '#02568A' }}><CircularProgress /></div>)}

                                    {this.state.helpSection === HelpSection.HelpLanding && (

                                        this.state.allHelpCategories ? this.state.allHelpCategories.map((helpCategories: HelpCategory) => (
                                            <Accordion key={helpCategories.catergoryName} defaultExpanded={true} style={{ padding: '0', border: 'none !important', marginBottom: '20px' }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={helpCategories.catergoryName}
                                                    id={helpCategories.catergoryName}
                                                    style={{ padding: '0', margin: '0', background: '#02568A' }}
                                                    color="#ffffff"
                                                    className="help"
                                                >
                                                    {helpCategories.catergoryName}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardDeck style={{ paddingTop: '10px' }}>
                                                        {sortArrayElementsByOrder(helpCategories.childHelpTopics, 'order', 'name')
                                                            .map((helpTopic: HelpTopic) => {
                                                                return (
                                                                    <Card key={helpTopic.name} style={{ flex: '0 25%' }}>
                                                                        {helpTopic.imageUrl !== 'missing' ? (
                                                                            <Link to={`?category=${helpTopic.helpCategory}&topic=${helpTopic.key}`}>
                                                                                <CardImg top width="100%" src={helpTopic.imageUrl} alt={helpTopic.name} />
                                                                            </Link>
                                                                        ) : <></>}

                                                                        <CardBody>
                                                                            <CardTitle>{helpTopic.title}</CardTitle>
                                                                            {helpTopic.description !== 'missing' && (
                                                                                <CardText>{helpTopic.description}</CardText>
                                                                            )}
                                                                            <Button to={`?category=${helpTopic.helpCategory}&topic=${helpTopic.key}`} tag={Link}>Read More</Button>
                                                                        </CardBody>
                                                                    </Card>
                                                                );
                                                            })}
                                                    </CardDeck>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                            : <></>
                                    )}

                                    {this.state.helpSection === HelpSection.HelpCategory && (
                                        this.state.activeHelpCategoryTopic ? <HelpCategoryTopic helpCategoryTopic={this.state.activeHelpCategoryTopic} /> : <></>
                                    )}

                                    {this.state.helpSection === HelpSection.HelpTopic && (
                                        this.state.activeHelpTopic ? <HelpTopicDetails helpTopic={this.state.activeHelpTopic} helpCategoryTopic={this.state.activeHelpCategoryTopic} allHelpCategories={this.state.allHelpCategories} /> : <></>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Footer isMobile={this.state.isMobile} />
                    </Col>
                </Row>
            </>
        );
    }

    private toggleDrawer = () => {
        this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
        });
    }

    private setIsTablet = (value: boolean) => {
        this.setState({
            isTablet: value
        });
    }

    private setIsMobile = (value: boolean) => {
        this.setState({
            isMobile: value
        });
    }

    private generateHelpCategories = (helpPagesData: any): void => {
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        let currentRouteKey: string = this.props.location.search ? this.props.location.search : '/help';
        let helpCategoryTitles: Array<string> = [];
        let helpTopics: Array<HelpTopic> = [];

        helpPagesData.forEach((helpPageObject: any) => {
            if (helpPageObject.helpCategory) {
                helpCategoryTitles.push(helpPageObject.helpCategory);
                let helpTopic: HelpTopic = {
                    key: helpPageObject.key,
                    title: helpPageObject.title,
                    name: helpPageObject.name,
                    helpCategory: helpPageObject.helpCategory,
                    description: helpPageObject.description,
                    htmlText: helpPageObject.htmlText,
                    order: helpPageObject.order,
                    url: helpPageObject.url,
                    videoUrl: helpPageObject.videoUrl,
                    videoHeight: helpPageObject.videoHeight,
                    videoWidth: helpPageObject.videoWidth,
                    pdfUrl: helpPageObject.pdfUrl,
                    imageUrl: helpPageObject.imageUrl,

                }
                helpTopics.push(helpTopic);
            }
        })
        let uniqueHelpCategoryTitles: Array<string> = [...new Set(helpCategoryTitles)];
        let uniqueHelpTopics: Array<HelpTopic> = [...new Set(helpTopics)];

        // Get category assets from the DataViewHelpAssets Table
        getHelpCategoriesAssetsTable(this.state.helpCategoryAssets, this.state.categoryAssetAccess).then(tableResponse => {
            let helpCategoryObjectArray = Object.values(tableResponse.value);
            let helpCategoriesAssets: HelpCategoryAsset[] = [];
            helpCategoryObjectArray.map((helpObject: any) => {
                const newAsset = {
                    key: helpObject.PartitionKey,
                    order: helpObject.Order,
                }
                helpCategoriesAssets.push(newAsset);
            });
            return helpCategoriesAssets;
        }).then(helpCategoriesAssets => {
            // Get topic assets from the DataViewAssets Table
            getHelpAssetsTable(this.state.helpAssets, this.state.assetAccess).then(tableResponse => {
                let helpObjectArray = Object.values(tableResponse.value);
                let helpTopicAssets: HelpTopicAsset[] = [];

                helpObjectArray.map((helpObject: any) => {
                    const newAsset = {
                        key: helpObject.PartitionKey,
                        title: helpObject.Title,
                        order: helpObject.Order,
                        description: helpObject.Description,
                        videoUrl: helpObject.Video,
                        videoHeight: helpObject.VideoHeight,
                        videoWidth: helpObject.VideoWidth,
                        pdfName: helpObject.Pdf,
                        imageName: helpObject.Thumbnail
                    }
                    helpTopicAssets.push(newAsset);
                })

                let helpTopicwAssets: Array<HelpTopic> = uniqueHelpTopics.map((helpTopic: HelpTopic) => {
                    let helpTopicWAsset: HelpTopicAsset | undefined = helpTopicAssets.find(asset => asset.key === helpTopic.key);

                    if (helpTopicWAsset && helpTopicWAsset !== undefined) {
                        let videoNewUrl: string = helpTopicWAsset.videoUrl ? helpTopicWAsset.videoUrl : 'missing';
                        let videoNewHeight: string = helpTopicWAsset.videoHeight ? helpTopicWAsset.videoHeight : helpTopic.videoHeight;
                        let videoNewWidth: string = helpTopicWAsset.videoWidth ? helpTopicWAsset.videoWidth : helpTopic.videoWidth;
                        let pdfNewUrl: string = helpTopicWAsset.pdfName ? (helpTopic.pdfUrl + helpTopicWAsset.pdfName) : 'missing';
                        let imageNewUrl: string = helpTopicWAsset.imageName ? (helpTopic.imageUrl + helpTopicWAsset.imageName) : 'missing';
                        let descriptionText: string = helpTopicWAsset.description ? helpTopicWAsset.description : 'missing';
                        let newTitle: string = helpTopicWAsset.title ? helpTopicWAsset.title : helpTopic.title;
                        let newOrder: number = helpTopicWAsset.order ? helpTopicWAsset.order : helpTopic.order;
                        // Create New Help Topic with new assets if any 
                        let updatedHelpTopic: HelpTopic = {
                            key: helpTopic.key,
                            title: newTitle,
                            name: helpTopic.name,
                            description: descriptionText,
                            helpCategory: helpTopic.helpCategory,
                            htmlText: helpTopic.htmlText,
                            order: newOrder,
                            url: helpTopic.url,
                            videoUrl: videoNewUrl,
                            videoHeight: videoNewHeight,
                            videoWidth: videoNewWidth,
                            pdfUrl: pdfNewUrl,
                            imageUrl: imageNewUrl,
                        }
                        return updatedHelpTopic;
                    } else {
                        // If no Help Topic found in the DataViewAssets Table are we are doing here is cleaning up some properties but keeping most of the 
                        // original items
                        let cleanedHelpTopic: HelpTopic = {
                            key: helpTopic.key,
                            title: helpTopic.title,
                            name: helpTopic.name,
                            description: 'missing',
                            helpCategory: helpTopic.helpCategory,
                            htmlText: helpTopic.htmlText,
                            order: helpTopic.order,
                            url: helpTopic.url,
                            videoUrl: 'missing',
                            videoHeight: helpTopic.videoHeight,
                            videoWidth: helpTopic.videoWidth,
                            pdfUrl: 'missing',
                            imageUrl: 'missing',
                        }
                        return cleanedHelpTopic;
                    }

                });

                // Combiine everything here, including Help Topic Categories with any assets and child help topics
                let helpTopicCategories: Array<HelpCategory> = uniqueHelpCategoryTitles.map((hct: string) => {
                    let helpCategory: HelpCategory;
                    let helpCategoryWAsset: HelpCategoryAsset | undefined;
                    const childHelpTopics: Array<HelpTopic> = helpTopicwAssets.filter((helpTopic: HelpTopic) => {
                        return helpTopic.helpCategory === hct;
                    });

                    helpCategoryWAsset = helpCategoriesAssets.find(asset => asset.key === hct);
                    if (helpCategoryWAsset) {
                        let categoryOrder: number = helpCategoryWAsset.order ? helpCategoryWAsset.order : 99999;
                        helpCategory = { catergoryName: hct, childHelpTopics: childHelpTopics, order: categoryOrder }
                    } else {
                        helpCategory = { catergoryName: hct, childHelpTopics: childHelpTopics, order: 99999 }
                    }

                    return helpCategory;
                }).sort((a, b) => (a.order > b.order) ? 1 : -1);

                this.setState({ isLoading: true, allHelpCategories: helpTopicCategories, urlRouteKey: currentRouteKey }, () => {
                    if (queryStringResult.category && !queryStringResult.topic) {
                        this.updateContentPerRoute(queryStringResult.category);
                    } else if (queryStringResult.category && queryStringResult.topic) {
                        this.updateContentPerRoute(queryStringResult.category, queryStringResult.topic);
                    }
                });

            });

        })
            .catch((error) => {
                this.setState({ majorError: true, errorMsg: undefined })
            });


    }

    private updateContentPerRoute = (helpCategoryString?: string | string[], helpTopicString?: string | string[]): void => {
        let currentRouteKey: string = this.props.location.search ? this.props.location.search : 'help';
        let helpTopicCategories: HelpCategory[] | undefined = [...this.state.allHelpCategories];
        let activeHelpTopic: HelpTopic | undefined;
        let activeHelpTopicCategory: HelpCategory | undefined;
        
        // Help landing section
        if (!helpCategoryString && !helpTopicString) {
            this.setState({ urlRouteKey: currentRouteKey, helpSection: HelpSection.HelpLanding });
        } else {

            activeHelpTopicCategory = helpTopicCategories.find((helpCategory: HelpCategory) => helpCategory.catergoryName === helpCategoryString);
            activeHelpTopic = activeHelpTopicCategory ? activeHelpTopicCategory.childHelpTopics.find((helpTopic: HelpTopic) => helpTopic.key === helpTopicString) : undefined;

            // Help category section
            if (helpCategoryString && !helpTopicString && activeHelpTopicCategory !== undefined) {
                this.setState({ urlRouteKey: currentRouteKey, helpSection: HelpSection.HelpCategory, activeHelpCategoryTopic: activeHelpTopicCategory });
                // Help topic section      
            } else if (helpCategoryString && helpTopicString && activeHelpTopicCategory !== undefined && activeHelpTopic !== undefined) {
                this.setState({ urlRouteKey: currentRouteKey, helpSection: HelpSection.HelpTopic, activeHelpCategoryTopic: activeHelpTopicCategory, activeHelpTopic: activeHelpTopic });
            }

        }

    }


};
export default connect()(Help);
