import * as React from 'react';
import { connect } from 'react-redux';
import * as pbi from 'powerbi-client';
import * as pbimodels from "powerbi-models";
import { Col, Row, Alert } from 'reactstrap';
import { State } from '../store/rootReducer';
import { AuthenticatedUser } from '../model/AuthenticatedUser';
import { CustomError } from '../model/Error';
import { Page } from 'powerbi-client';
import { EmbedResult } from '../model/EmbedResult';
import LeftNav from './LeftNav';
import { getAppInsights } from '../common/TelemetryService';
import { setReportAction } from '../store/actions/reportActions';
import { setAllErrorAction } from '../store/actions/errorActions';
import { bindActionCreators } from 'redux';
import { apiReportsWithFirmOverride } from '../api/Api';
import { myMSALObj, tokenRequest } from '../store/authConfig';
import { AuthResponse } from 'msal';
import { handleApiErrors } from '../common/GeneralHelpers';
import Footer from './layout/Footer';
import NavMenu from './NavMenu';
import Error from './layout/Error';
import NoReports from './NoReports';

export interface TokenInfo extends pbi.IEmbedConfiguration {
    reportId?: string,
    mode?: string,
    name?: string,
}

interface IHomeStoreProps {
    currentlyAuthenticatedUser: AuthenticatedUser
    pages: Array<Page>;
    report: EmbedResult[];
    isOpen: boolean;
    siteError: CustomError;
    isLoading: any,
}

interface IHomeProps {
    currentPage: string;
    currentReportGroup: string;
    hideNav: boolean;
    noreload: boolean;
    match: any;
}
// Dispatch to redux
interface IHomeDispatchProps {
    setReport: (report: EmbedResult[]) => void;
    setError: (error: CustomError) => void;
}
interface IHomeState {
    currentPage: string;
    currentReportGroup: string;
    majorError: boolean;
    isDrawerOpen: boolean;
    errorMsg?: string;
    isTablet: boolean;
    isMobile: boolean;
    reload: boolean;
}

export enum iconEnum {
    faAddressBook,
    faComments,
    faGraduationCap,
    faPrint
}

class Home extends React.Component<IHomeDispatchProps & IHomeProps & IHomeStoreProps, IHomeState>{
    private pwrbi: pbi.service.Service;
    private rootElement?: HTMLDivElement | null;
    private report?: pbi.Embed | null;
    constructor(props: IHomeDispatchProps & IHomeProps & IHomeStoreProps) {
        super(props);
        this.pwrbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        this.report = null;
        this.state = {
            currentPage: '',
            currentReportGroup: '',
            majorError: false,
            isDrawerOpen: true,
            errorMsg: undefined,
            isMobile: false,
            isTablet: false,
            reload: true
        };
    }

    private embed() {
        var currentReport = this.getCurrentReport();
        if (!currentReport) return;
        var currentDateTime = new Date();
        var expirationDate = new Date(currentReport.embedToken.expiration);
        if (expirationDate < currentDateTime) {
            myMSALObj.acquireTokenSilent(tokenRequest)
                .then((tokenResponse: AuthResponse) => {
                    apiReportsWithFirmOverride(this.props.currentlyAuthenticatedUser.impersonatedFirmId, tokenResponse.accessToken)
                        .then((apiResp: any) => {
                            this.props.setReport(apiResp);
                        });
                })
                .catch((error: any) => {
                    // fallback to interaction when silent call fails
                    this.setState({ majorError: true, errorMsg: "Authentication failed." });
                    return myMSALObj.acquireTokenRedirect(tokenRequest);
                });
        }

        let customLayout: pbi.models.ICustomLayout = { displayOption: pbi.models.DisplayOption.ActualSize }
        let isMobile = window.innerWidth < 640;
        let settings: pbi.IEmbedSettings = {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            customLayout: customLayout,
            layoutType: isMobile ? pbi.models.LayoutType.MobilePortrait : pbi.models.LayoutType.Master
        };

        let config: TokenInfo = {
            reportId: currentReport.id,
            accessToken: currentReport.embedToken.token,
            type: 'report',
            embedUrl: currentReport.embedUrl,
            tokenType: pbimodels.TokenType.Embed,
            permissions: pbimodels.Permissions.All,
            settings: settings,
            pageName: !this.state.currentPage && this.props.pages.length > 0 ? this.props.currentPage : this.state.currentPage,
        }

        ///// Somewhere in other navigation logic, when navigating to a report page which is the same name as a page in another report (e.g. Dashboard
        ///// or Data Glossary exist in both Coverage Explorer and DataView), the system is trying to navigate to whichever appears first.  Making the
        ///// second one unreachable by site navigation or sometimes defaulting to the Dashboard for the latter report.  This logic is meant to confirm
        ///// that the report being embedded matches the URL being requested.
        const currentReportGroupPages = (this.props.pages.find((p: any) => p.reportGroup === currentReport?.reportGroup) as any)?.reportPages;
        if (currentReportGroupPages && !currentReportGroupPages.map((p: any) => p.name).includes(config.pageName)) {
            const pathParts = window.location.pathname.split('/');
            const path = pathParts[pathParts.length - 1];
            config.pageName = currentReportGroupPages.find((p: any) => p.displayName.toLowerCase().replace(' ', '-') === path).name;
        }
        /////

        if (this.rootElement) {
            if (this.props.currentlyAuthenticatedUser.isALAS()) {
                this.pwrbi.reset(this.rootElement);
            }
            this.report = this.pwrbi.embed(this.rootElement, config);
        }
        getAppInsights().trackPageView();

        //Refresh token in background after token expires
        this.updateToken();
    }

    public componentDidMount() {
        if (this.props.siteError.errorTitle !== ``) {
            if (this.props.siteError.showErrorPage) {
                this.setState({ majorError: true });
            }
        }

        if (this.props.report && this.props.report.length > 0) {
            this.embed();
        }
    }

    public componentDidUpdate() {
        if (this.state.isDrawerOpen && this.props.report && this.props.report.length > 0 && this.state.reload && !this.props.noreload) {
            this.embed();
        }

        if (this.props.report) {

            const props = this.props;

            this.report?.on('pageChanged', function (event: any) {
                const pagename = event.detail?.newPage.displayName;

                if (['DataView', 'DataView.Mobile', 'MapView'].includes(props.currentReportGroup)) {

                    var el = document.getElementsByClassName('nav-link active')[0];

                    if (el) {
                        document.getElementsByClassName('nav-link active')[0].classList.remove('active');
                    }

                    if (pagename.toLowerCase() === "dashboard") {
                        if (window.location.pathname != "/") {
                            window.history.replaceState(null, "", "/" + props.currentReportGroup + "/dashboard")
                        }
                        document.title = "ALAS Data Insights - Dashboard";
                        const links = document.getElementsByClassName('nav-link');

                        Array.prototype.forEach.call(links, function (link) {
                            if (link.href.endsWith('/' + props.currentReportGroup + '/dashboard')) {
                                link.classList.add('active');
                            }
                        });
                    }
                    else {
                        const url = "/" + props.currentReportGroup + "/" + pagename.replace(/\s+/g, '-').toLowerCase();
                        document.querySelector('[href="' + url + '"]')?.classList.add('active');
                    }

                } else {
                    if (window.location.pathname === "/") {
                        var el = document.getElementsByClassName('nav-link active')[0];

                        if (el) {
                            document.getElementsByClassName('nav-link active')[0].classList.remove('active');
                        }

                        if (pagename.toLowerCase() === "dashboard") {
                            document.getElementsByClassName('nav-link dashboard')[0].classList.add('active');
                        }
                        else {
                            const url = "/" + pagename.replace(/\s+/g, '-').toLowerCase();
                            document.querySelector('[href="' + url + '"]')?.classList.add('active');
                        }
                    }

                }
            });
        }
    }

    public render() {
        return (
            <>
                <Error show={this.state.majorError} errorMsg={this.state.errorMsg} />
                <Row className='mainRow'>
                    <LeftNav currentReportGroup={this.props.currentReportGroup} currentPage={this.state.currentPage} toggleDrawer={this.toggleDrawer} setIsMobile={this.setIsMobile} setIsTablet={this.setIsTablet} />
                    <Col style={{ padding: this.state.isMobile ? '0px 0px 0px 20px' : '0px 0px 0px 1%', marginLeft: this.state.isMobile ? '0px' : this.state.isTablet ? '30px' : this.state.isDrawerOpen ? '220px' : '20px' }}>
                        {
                            (this.props.isLoading.pages === false && this.props.pages.length === 0) ?
                                <div id="wrapper">
                                    <div id="content" className="report-wrapper">
                                        <NoReports />
                                    </div>
                                </div> :
                                <>
                                    <NavMenu isMobile={this.state.isMobile} />
                                    <div id="wrapper">
                                        <div id="content" className="report-wrapper">
                                            <div className="embed-responsive embed-responsive-16by9 {window.innerWidth > 640 ? 'desktopPowerBi' : 'mobilePowerBi'}" ref={(ref) => this.rootElement = ref} ></div>
                                        </div>
                                    </div>
                                </>
                        }
                        <Footer isMobile={this.state.isMobile} />
                    </Col>
                </Row>
            </>
        );
    }

    private toggleDrawer = () => {
        this.setState({
            isDrawerOpen: !this.state.isDrawerOpen,
            reload: false
        });
    }

    private setIsTablet= (value: boolean) => {
        this.setState({
            isTablet: value
        });
    }

    private setIsMobile = (value: boolean) => {
        this.setState({
            isMobile: value
        });
    }

    private getCurrentReport = () => {
        var currentReport = this.props.report.find(r => r.reportGroup === this.props.match.params.reportGroup);
        if (!currentReport)
            currentReport = this.props.report.find(r => r.reportGroup === this.props.currentReportGroup);
        return currentReport;
    }

    private updateToken() {
        var alertContainer = document.getElementsByClassName("alert")[0] as HTMLDivElement;
        if (alertContainer !== undefined) {
            alertContainer.classList.remove('show');
            alertContainer.classList.add('hide');
            alertContainer.style.display = 'none';
            clearInterval(undefined);//Clear previous internal
            clearTimeout(undefined);
            var currentReport = this.getCurrentReport();
            if (!currentReport)
                return;
            var currentTime = Date.now();
            var expirationTime = Date.parse(currentReport.embedToken.expiration);
            var timeout = expirationTime - currentTime;
            var refreshInterval = 10 * 60 * 1000; //10 minutes internal to renew B2C token
            if (timeout > 0) {
                setInterval(() => {
                    myMSALObj.acquireTokenSilent(tokenRequest)
                        .then((tokenResponse: AuthResponse) => {
                            currentTime = Date.now();
                            timeout = expirationTime - currentTime;
                            if (timeout <= refreshInterval) {
                                setTimeout(() => {
                                    apiReportsWithFirmOverride(this.props.currentlyAuthenticatedUser.impersonatedFirmId, tokenResponse.accessToken)
                                        .then((apiResp: any) => {
                                            //Get htmlelement by class name and set new token is expired
                                            const reportContainer = document.getElementsByClassName("embed-responsive")[0] as HTMLElement;
                                            var reportScript = this.pwrbi.get(reportContainer);
                                            reportScript.setAccessToken(apiResp.embedToken.token);
                                            //Set new expiration time
                                            expirationTime = Date.parse(apiResp.embedToken.expiration);
                                        }).catch((error: any) => {
                                            this.props.setError(handleApiErrors(error, `Error Getting Reports with Override:`));
                                        });
                                }, timeout);
                            }
                        })
                        .catch((error: any) => {
                            this.setState({ majorError: true, errorMsg: "Authentication failed." });
                        });
                }, refreshInterval);
            }
        }
    }
};

function mapStateToProps(state: State) {
    return {
        currentlyAuthenticatedUser: state.currentlyAuthenticatedUser,
        pages: state.pages,
        report: state.report,
        isOpen: state.isOpen,
        isLoading: state.isLoading,
        siteError: state.error
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setReport: setReportAction,
            setError: setAllErrorAction,
        }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
