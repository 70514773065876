import React from 'react';
import { Container } from 'reactstrap';
import sizeMe from 'react-sizeme';


function LayoutComponet(props: any) {

    return (
        <React.Fragment>
            <Container fluid>
                {props.children}
            </Container>           
        </React.Fragment>
    );
    
  }
  
  export default sizeMe({ monitorWidth: true })(LayoutComponet);

