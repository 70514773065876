import { ApplicationInsights, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { Telemetry_Instrumentation_Key } from '../store/authConfig';
import { createBrowserHistory } from 'history';
import { AuthenticatedUser } from '../model/AuthenticatedUser';

let initialized = false;;
let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Initialize the App Insights Telemetry Service
 * @param {string} instrumentationKey - Application Insights Instrumentation Key
 * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
 * @return {void}
 */
const initializeTelemetryService = (instrumentationKey: string, browserHistory: any) => {
    if (!browserHistory) {
        throw new Error('Browser history not provided');
    }
    if (!instrumentationKey) {
        throw new Error('Instrumentation key not provided')
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();

    initialized = true;
};

export const getAppInsights = () => {
    if (!initialized) {
        let history = createBrowserHistory();
        initializeTelemetryService(Telemetry_Instrumentation_Key, history);
    }
    return appInsights;
};

export const getReactPlugin = () => {
    if (!initialized) {
        let history = createBrowserHistory();
        initializeTelemetryService(Telemetry_Instrumentation_Key, history);
    }
    return reactPlugin;
};

export const setAuthenticatedUserForTelemetry = (user: AuthenticatedUser) => {
    if (!initialized) {
        let history = createBrowserHistory();
        initializeTelemetryService(Telemetry_Instrumentation_Key, history);
    }
    appInsights.setAuthenticatedUserContext(user.email, undefined, true);
}

export const logException = (errorId: string, errorMessage: string, severityLevel: number) => {
    if (!initialized) {
        let history = createBrowserHistory();
        initializeTelemetryService(Telemetry_Instrumentation_Key, history);
    }

    let exception: IExceptionTelemetry = {
        error: new Error(errorId + ` ` + errorMessage),
        severityLevel:severityLevel
    };
    appInsights.trackException(exception);
}
 