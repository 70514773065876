import { StringDict } from "msal/lib-commonjs/MsalTypes";


export class AuthenticatedUser  {
    constructor(public id: string = ``,         
                // public name: string = ``,
                public email: string = ``,
                //public originalUserId: string = ``,
                public userRoles: string[] = [],
                // public securityGroup: string = ``,
                public firmNumber: string = ``,
                public impersonatedFirmId: string = ``) {
    }

    // initials = () => {
    //     if (this.name) {
    //         return `${this.name.substring(0, 1)}${this.lastName().substring(0, 1)}`;
    //     } else {
    //         return '';
    //     }        
    // }

    // lastName = (): string => {
    //     if (this.name) {    
    //         let name = this.name.split(' ' );
    //         return name[name.length - 1];
    //     } else {
    //         return '';
    //     }
    // }

    isALAS = (): boolean => {
        return this.email.endsWith("@alas.com");
    }

    isAuthorized = (): boolean => {
        return true; // As of the addition of MapView, all ALAS users are permitted to access this application.
    }
    
}

export const convertStringDict = (idToken: StringDict): AuthenticatedUser => {
    const roles: string[] = [ ...idToken.userRoles ];
    return new AuthenticatedUser(
        idToken.sub,
        // idToken.name,
        idToken.email,
        //idToken.originalUserId,
        roles,
        // idToken.securityGroup,
        idToken.firmNumber,
        ""
    );
}