import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';
import rootReducer from './rootReducer';
import { appEnvironment } from './authConfig';
import { Environment } from '../model/Environment';
const logger = createLogger({ collapsed: true});

 
export default function configureStore(history: History, initialState?: ApplicationState) {
 
    let middleware; 
    if (appEnvironment === Environment.DEV) {
        middleware = [thunk,logger,routerMiddleware(history)];
    } else {
        middleware = [thunk,routerMiddleware(history)];
    }

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer(history),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
