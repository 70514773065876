import React from 'react';

const NoReports = (props: any) => {
    return (
        <>
            You do not appear to have access to these resources. If you believe this is an error, please contact membersupport@alas.com.
        </>
    );
}

export default NoReports;
