import { CustomError } from '../model/Error';
import { logException, getAppInsights } from '../common/TelemetryService';

export function sortArrayElementsByOrder(array:Array<any>, propertyOne:string, propertyTwo?:string, ): Array<any> {
    array.sort((a, b) => {
        if (a[propertyOne] && b[propertyOne] && a[propertyOne] > b[propertyOne]) {
            return 1;
        } else if (a[propertyOne] && b[propertyOne] && b[propertyOne] > a[propertyOne]) {
            return -1;
        } else if (a[propertyOne] && b[propertyOne] && a[propertyOne] === b[propertyOne] && propertyTwo) {
            // if the prop name one is the same then use  prop name two
            if (a[propertyTwo] < b[propertyTwo]) return -1;
            if (a[propertyTwo] > b[propertyTwo]) return 1;
            return 0;
        } else {
            return 0;
        }    
    });
    return array;
}

export function handleApiErrors(error:any, errorTitle:string): CustomError {
        if(showErrorPage(error.status)){
            let pageError:CustomError = {
                errorTitle: errorTitle,
                statusCode: error.status,
                errorMessage: error.statusText,
                showErrorPage: true
            };
            // Track exception in App insights
            logException(errorTitle, error.status + ' ' + error.statusText, 4 );
            return pageError;
            
        }else{
            let pageError:CustomError = {
                errorTitle: errorTitle,
                statusCode: error.status,
                errorMessage: error.statusText,
                showErrorPage: false
            };
            logException(errorTitle, error.status + ' ' + error.statusText, 3 );
            return pageError;
        }

}

export function showErrorPage(statusCode:number):boolean {
    let showPage:boolean = false;
    switch (statusCode) {
        case 502:
        case 503:
            showPage = true;
        break;
      default:
        return showPage;
    }
    return showPage;
}
 