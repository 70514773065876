import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Card, CardImg, CardTitle, CardText, CardDeck, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HelpCategory, HelpTopic } from '../../model/HelpTopic';
import { sortArrayElementsByOrder } from '../../common/GeneralHelpers';
import { Col, Row } from 'reactstrap';

interface IHelpTopicProps {
    helpCategoryTopic: HelpCategory;
}

interface IHelpTopicState {
    isDrawerOpen: boolean;
}


class HelpCategoryTopic extends React.Component<IHelpTopicProps, IHelpTopicState>{
    constructor(props: any) {
        super(props);
        this.state = {
            isDrawerOpen: true
        };
    }
    public render() {
        const helpCategoryTitle = this.props.helpCategoryTopic ? this.props.helpCategoryTopic.catergoryName : '';

        return (
            <Row>
                <Col md={12} lg={12} style={{ paddingBottom: '0px' }} >
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem active>{helpCategoryTitle}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>

                </Col>
                <Col>
                    {this.props.helpCategoryTopic ? (
                        <div style={{ padding: '0' }} color="#ffffff" >
                            <h3 className="help-topic ">{helpCategoryTitle}</h3>
                            <CardDeck >
                                {sortArrayElementsByOrder(this.props.helpCategoryTopic.childHelpTopics, 'order', 'name').map((helpTopic: HelpTopic) => {
                                    return (
                                        <Card key={helpTopic.name} style={{ flex: '0 25%' }}>
                                            {helpTopic.imageUrl !== 'missing' ? (
                                                <Link to={`?category=${helpTopic.helpCategory}&topic=${helpTopic.key}`}>
                                                    <CardImg top width="100%" src={helpTopic.imageUrl} alt={helpTopic.name} />
                                                </Link>
                                            ) : <></>}

                                            <CardBody>
                                                <CardTitle> {helpTopic.title} </CardTitle>
                                                {helpTopic.description !== 'missing' && (
                                                    <CardText>{helpTopic.description}</CardText>
                                                )}
                                                <Button to={`?category=${helpTopic.helpCategory}&topic=${helpTopic.key}`} tag={Link} >Read More</Button>
                                            </CardBody>
                                        </Card>
                                    );
                                })}
                            </CardDeck>
                        </div>
                    )
                        : <></>
                    }
                </Col>

            </Row>
        );
    }

};

export default connect()(HelpCategoryTopic);

