import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Subdomain } from '../../store/authConfig';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const footer = {
    background: '#EBEBEB',
    color: '#444444',
    fontSize: '12px',
    marginTop: '20px',
    paddingTop: '15px',
};


export default class Footer extends React.PureComponent<{ isMobile: boolean }, { isLoggedIn: boolean }> {
    public state = {
        isLoggedIn: false
    };

    public render() {
        var d = new Date();
        return (
            <footer style={footer}>
                <Row>
                    <Col xs={6} sm={2} md={2} lg={1}>
                        <a href={`https://${Subdomain}.alas.com/`} target="_blank"><img style={{ width: '100%', maxWidth: '70px', margin: this.props.isMobile ? '3px 20px 0 0' : '3px 20px 0 20px' }} src={'/Logo_100_gray.png'} alt='ALAS' /></a>
                    </Col>
                    <Col xs={6} style={{ textAlign: 'right', paddingRight: '30px' }} className="privacy-mobile">
                        <a style={{ color: '#02568A', textDecoration: 'none', fontWeight: '700' }} href="https://www.alas.com/privacypolicy.aspx" target='_blank' rel="noopener noreferrer">PRIVACY POLICY</a>
                        {/*<a href="https://twitter.com/ALASltd" target='_blank' rel="noopener noreferrer">*/}
                        {/*    <TwitterIcon />*/}
                        {/*</a>*/}
                        <a href="https://www.linkedin.com/company/alas-ltd/" target='_blank' rel="noopener noreferrer">
                            <LinkedInIcon />
                        </a>
                    </Col>
                    <Col sm={12} md={7} lg={6}>
                        <div style={{ paddingTop: this.props.isMobile ? '10px' : '0px' }}>
                            {" "}311 South Wacker Drive, Suite 5700{"  "}
                            {<br className="privacy-mobile" />}Chicago, IL 60606
                            {<br className="privacy-mobile" />}{!this.props.isMobile && <FontAwesomeIcon icon={faPhoneAlt} color="#444" />}(312) 697-6900<br />{<br className="privacy-mobile" />}
                        </div>
                        <div>&copy; {d.getFullYear()} by Attorneys' Liability Assurance Society (ALAS). {!this.props.isMobile && "All Rights Reserved."}</div>
                    </Col>
                    <Col sm={5} md={3} lg={5} style={{ textAlign: 'right', paddingRight: '40px' }} className="privacy-desktop">
                        <a style={{ color: '#02568A', textDecoration: 'none', fontWeight: '700' }} href="https://www.alas.com/privacypolicy.aspx" target='_blank' rel="noopener noreferrer">PRIVACY POLICY</a>
                        {/*<a href="https://twitter.com/ALASltd" target='_blank' rel="noopener noreferrer">*/}
                        {/*    <TwitterIcon />*/}
                        {/*</a>*/}
                        <a href="https://www.linkedin.com/company/alas-ltd/" target='_blank' rel="noopener noreferrer">
                            <LinkedInIcon />
                        </a>
                    </Col>
                </Row>
            </footer>
        );
    }
}
