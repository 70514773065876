
import { Reducer } from 'redux';
import { SET_REPORT } from '../actions/actionTypes';
import { EmbedResult } from '../../model/EmbedResult';

export const reportReducer: Reducer<EmbedResult> = (state = new EmbedResult(), action) => {
    switch (action.type) {
        case SET_REPORT:
            return action.payload;
        default:
            return state;
    }
};