
import { Reducer } from 'redux';
import { CURRENT_USER_SET } from '../actions/actionTypes';
import { AuthenticatedUser } from '../../model/AuthenticatedUser';

export const userReducer: Reducer<AuthenticatedUser> = (state = new AuthenticatedUser(), action) => {
    switch (action.type) {
        case CURRENT_USER_SET:           
            let user = action.payload;
            return user;      
        default:
            return state;
    }
};