export class HelpTopic {
    constructor(
        public key: string = ``,
        public title: string = ``,
        public name: string = ``,
        public helpCategory: string = ``,
        public description: string = ``,
        public htmlText: string = ``,
        public order: number = -1,
        public url: string = ``,
        public videoUrl: string = ``,
        public videoHeight: string = ``,
        public videoWidth: string = ``,
        public pdfUrl: string = ``,
        public imageUrl: string = ``,
    ) { }
}

export class HelpCategory {
    constructor(
        public catergoryName: string = ``,
        public childHelpTopics: Array<HelpTopic> = [],
        public order: number = 99999
    ) { }
}

export type HelpTopicAsset = {
    key: string;
    title: string | '';
    description: string;
    order: number;
    videoUrl: string;
    videoHeight: string;
    videoWidth: string;
    pdfName: string;
    imageName: string;
}

export type HelpCategoryAsset = {
    key: string;
    order: number;
}

export enum HelpSection {
    HelpLanding = 0,
    HelpCategory = 1,
    HelpTopic = 2,
    HelpLoading = -1,
}