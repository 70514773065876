﻿import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface IErrorProps {
    show: boolean;
    errorMsg?: string;
}

interface IErrorState {
    open: boolean;
}


class Error extends React.Component<IErrorProps, IErrorState>{

    constructor(props: IErrorProps) {
        super(props);
        this.state = {
            open: this.props.show
        };
    }

    public componentDidMount() {
        setTimeout(() => this.setState({ open: false }), 10000); //wait 10 seconds, then close
    }

    public render() {
        return (
            <>
                {this.state.open && <Alert severity="error" variant="filled" className="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                this.setState({ open: false });
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }>
                    <AlertTitle><strong>Error: </strong> {this.props.errorMsg ?? "An error occurred while processing your request. Please try again later."}</AlertTitle>
                </Alert>}
            </>
        );
    }
};

export default Error;
