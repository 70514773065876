import * as React from 'react';
import { connect } from 'react-redux';
import * as pbi from 'powerbi-client';
import { Col, ListGroup, ListGroupItem, NavLink, Card, CardHeader, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faHome, faShareAlt, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { State } from '../store/rootReducer';
import { toggleAction } from '../store/actions/isOpenActions';
import { bindActionCreators } from 'redux';
import { myMSALObj, Subdomain } from '../store/authConfig';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';

export interface TokenInfo extends pbi.IEmbedConfiguration {
    reportId?: string,
    mode?: string,
    name?: string,
}

interface ILeftNavProps {
    pages: any,
    currentPage: string,
    currentReportGroup: string,
    toggleDrawer?: any;
    setIsMobile?: any;
    setIsTablet?: any;
}

interface ILeftNavStoreProps {
    isOpen: boolean

}

interface ILeftNavState {
    currentPage: string;
    currentReportGroup: string;
    isDrawerOpen: boolean;
    isTablet: boolean;
    isMobile: boolean;
    openGroup: string;
}

// Dispatch to redux
interface ILeftNavDispatchProps {
    toggle: (isOpen: boolean) => void;
}

const listItemStyle = {
    cursor: 'pointer',
    background: '#EBEBEB',
    color: '#02568A !important'
};

const leftPanel = {
    background: '#EBEBEB', padding: '0', transitionTimingFunction: 'linear',
    transitionDuration: '.2s',
    minWidth: '200px',
    maxWidth: '200px',
    height: 'calc(100vh - 58px)',
    overflowY: 'auto' as 'auto',
    position: 'fixed' as 'fixed',
};

export enum iconEnum {
    faAddressBook,
    faComments,
    faGraduationCap,
    faPrint
}


class LeftNav extends React.Component<ILeftNavProps & ILeftNavStoreProps &ILeftNavDispatchProps, ILeftNavState>{
    constructor(props: any) {
        super(props);       
        this.state = {
            currentPage: '',
            currentReportGroup: props.currentReportGroup,
            isDrawerOpen: window.innerWidth > 1080,
            isTablet: window.innerWidth > 576 && window.innerWidth <= 1080,
            isMobile: window.innerWidth <= 576,
            openGroup: props.currentReportGroup
        };
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        return () => window.removeEventListener('resize', this.handleResize);
    }

    toggleAccordion = (e: any) => {
        if (this.state.openGroup === e.target.dataset.group) {
            this.setState({ openGroup: ' ' });
        } else {
            this.setState({ openGroup: e.target.dataset.group });
        }
    }

    public render() {
        
        return (
            <>
                <Drawer
                    anchor={'left'}
                    variant={this.state.isTablet || this.state.isMobile ? 'temporary' : 'persistent'}
                    open={this.state.isDrawerOpen}
                    onClose={this.toggleDrawer}
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                        }
                    }}
                >
                    <Col sm={this.props.isOpen ? 2 : 1} md={2} lg={2} style={leftPanel} >
                        <ListGroup className={`leftNav`}>
                            <a href={`https://${Subdomain}.alas.com/`} style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '1rem 0' }} target="_blank"><img src={'/Logo_100_gray.png'} alt='ALAS Data Insights' /></a>
                            <div className="nav-title">Reports</div>
                            {
                                this.props.pages ? this.props.pages.map((page: any, i: Number) => (
                                    <Card key={page.reportGroup}>
                                        <CardHeader onClick={this.toggleAccordion} data-group={page.reportGroup}>{page.displayName}</CardHeader>
                                        <Collapse isOpen={(this.state.openGroup === page.reportGroup) || (!this.state.openGroup && i === 0)}>
                                            {page.reportPages.map((reportPage: any) => (
                                                <ListGroupItem className={'nav-link ' + this.isActive(page.reportGroup, reportPage.displayName, i)} style={listItemStyle} key={reportPage.name} to={this.getLink(page.reportGroup, reportPage.displayName)} tag={Link} action>
                                                    {reportPage.displayName}
                                                </ListGroupItem>
                                            ))}
                                            {
                                                page.showHelp &&
                                                <ListGroupItem className={(this.props.currentReportGroup === page.reportGroup && this.props.currentPage === 'help') ? 'nav-link active' : 'nav-link'} style={listItemStyle} to={`/${page.reportGroup}/help`} tag={Link} action>
                                                    <FontAwesomeIcon icon={faQuestionCircle} /> Help
                                                </ListGroupItem>
                                            }
                                        </Collapse>
                                    </Card>
                                )) : <><div style={{ margin: '50vh 50%', color: '#02568A' }}><CircularProgress /></div></>
                            }
                            <div className="nav-title">Application</div>
                            <a href={`https://${Subdomain}.alas.com/`} className={'nav-link'} style={listItemStyle} target="_blank"><FontAwesomeIcon icon={faHome} /> ALAS Home</a>
                            <a href={`https://${Subdomain}.alas.com/portal/alas-edge/overview`} className={'nav-link'} style={listItemStyle} target="_blank"><FontAwesomeIcon icon={faShareAlt} /> ALAS Edge</a>
                            <a href={`https://${Subdomain}.alas.com/portal`} className={'nav-link'} style={listItemStyle} target="_blank"><FontAwesomeIcon icon={faUsers} /> Members Home</a>
                            <div className="logout-container">
                                <NavLink className="members-link" onClick={() => { myMSALObj.logout() }}>
                                    Logout
                                </NavLink>
                            </div>
                        </ListGroup>
                    </Col>
                </Drawer>
                <Col style={{ marginLeft: this.state.isMobile ? this.state.isDrawerOpen ? '200px' : '0px' : this.state.isDrawerOpen ? '200px' : '0px', transitionTimingFunction: 'linear', transitionDuration: '.2s' }} className="left-drawer-toggle-container">
                    <a href="/"><img style={{ width: '70%', margin: '42px 0 0 4px', opacity: this.state.isDrawerOpen ? '0' : '1' }} src={'/Logo.png'} alt='ALAS' /></a>
                    <div className="toggle-btn" color="primary" onClick={() => this.toggleDrawer()}>
                        {this.state.isMobile ? this.state.isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon /> : this.state.isDrawerOpen ? (
                            <FontAwesomeIcon icon={faChevronLeft} color="#1976d2" />
                        ) : (
                            <FontAwesomeIcon icon={faChevronRight} color="#1976d2" />
                        )}
                    </div>
                </Col>
               
            </>
        );
    }

    private toggleDrawer = () => {
        this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
        });

        this.props.toggleDrawer();
    }

    private handleResize = () => {
        this.setState({ isTablet: window.innerWidth > 576 && window.innerWidth <= 1080, isMobile: window.innerWidth <= 576, isDrawerOpen: window.innerWidth > 1080 });

        this.props.setIsMobile(window.innerWidth <= 576)
        this.props.setIsTablet(window.innerWidth > 576 && window.innerWidth <= 1080);
    }

    private isActive(groupname: string, pagename: string, groupindex: Number) {
        if ((pagename.toLowerCase() === 'dashboard' && window.location.pathname === "/") || `/${encodeURIComponent(groupname)}/${pagename.replace(/\s+/g, '-').toLowerCase() }` === window.location.pathname) {
            document.title = "ALAS Data Insights - " + pagename;

            if (pagename.toLowerCase() === 'dashboard' && ((window.location.pathname === "/" && groupindex === 0) || (`/${encodeURIComponent(groupname) }/${pagename.replace(/\s+/g, '-').toLowerCase()}` === window.location.pathname)))
                return 'dashboard active';
            else if (pagename.toLowerCase() !== 'dashboard')
                return 'active';
        }
    }

    private getLink(groupname: string, pagename: string) {
        return `/${groupname}/` + pagename.replace(/\s+/g, '-').toLowerCase();
    }

    private capitalizer(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

};

function mapStateToProps(state: State) {
    return {
        currentlyAuthenticatedUser: state.currentlyAuthenticatedUser,
        pages: state.pages,
        isOpen: state.isOpen
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            toggle: toggleAction,
        },
        dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
